

#logoText {
  width:  300px;
  color:white;
  font-family: 'Dogica', sans-serif;
  line-height: 1.5;
  font-size: 16px;
  margin:auto;
  text-align: right;
  white-space: pre-line;
}

#logoTextTitle {
  font-size: 20px;
}

#dopisek {
  font-size: 14px;
  color:white;
  text-align: center;
}

#logoTextCenter{
  color:white;
  font-family: 'Dogica', sans-serif;
  line-height: 1.5;
  font-size: 24px;
  margin:auto;
  white-space: pre-line;
  margin-top: 10px;
  text-align: center;
}


#introView{
  color:white;
  font-family: 'Dogica', sans-serif;
  line-height: 1.5;
  font-size: 14px;
  margin:auto;
}

#introImgView{
  margin:auto;
}

#exitView{
display:grid;
}


#campImg {
  max-width: 100px;
  max-height: 100px;
  margin:auto;
 }

#connectImg {
  width: 300px;
  max-height: 300px;
  margin:auto;
 }

#bodySlotGrid{
  display:flex;
  width:  300px;
  margin: auto;
}

 #introGrid {
  display:flex;
 }

#buttonGrid{
  display:flex;
  padding-top: 20px;
  padding-bottom:20px;
}

#metamaskEmpty{
  color:white;
  text-align: center;
  background-repeat: no-repeat; /* Obrazek nie będzie powtarzany */
  background-position: center;
  background-size: cover;
  width: 322px; /* Ustawienie szerokości przycisku */
  height:  92px; /* Ustawienie wysokości przycisku */
  background-image: url('./../assets/metamask.png');
  background-color: black;
  border: black;
  margin:auto;
}


.okragla-kontrolka {
  margin:auto;
  width: 200px; /* Dowolna szerokość */
  height: 200px; /* Dowolna wysokość */
  border-radius: 50%; /* Ustawienie okrągłego kształtu */
  overflow: hidden; /* Ukrycie części obrazka, które wykraczają poza kontrolkę */
}

.okragla-kontrolka img {
  width: 100%; /* Dostosowanie szerokości zdjęcia do kontrolki */
  height: 100%; /* Dostosowanie wysokości zdjęcia do kontrolki */
  object-fit: cover; /* Dopasowanie zdjęcia do rozmiaru kontrolki */
}

@media only screen and (max-width: 768px) {
  #metamaskEmpty {

    visibility: visible;
}
#bodySlotGrid{
  width:  120px;
  margin-left:10px;
  margin-right:10px
}

}

#whiteListClock{
  display: block;
  color:white;
  padding-bottom:20px;
}

#labeTime{
  color:#fb97c9;
  font-family: 'Dogica', sans-serif;
  font-size: 14px;
  margin:auto;
  white-space: pre-line;
  text-align: center;
 }

 #labelWhiteListClock{
  padding-top:2px;
  color:#fb97c9;
  font-family: 'Dogica', sans-serif;
  font-size: 20px;
  margin:auto;
  text-align: center;
 }
 #labelClock{
  
  color:#fb97c9;
  font-family: 'Dogica', sans-serif;
  font-size: 36px;
  margin:auto;
  text-align: center;
 }


#Body{
  display: flex;
  margin-top:80px;
  margin-left:200px;
  margin-right:200px;
  margin-bottom:40px;
 }


 @media only screen and (max-width: 768px) {
  #Body {
    width: 30%;
    height: 30%;
    margin-top:0px;
    margin-left:0px;
    margin-right:0px;
    margin-bottom:0px;
    visibility: visible;
  }

  #connectImg {
    width: 100px;
    max-height: 100px;
   }

   .okragla-kontrolka {
    width: 100px;
    height: 100px;
}

}


#buttonGrid2{
  display:flex;
  font-size: 16px;
  padding-top: 10px;
}

#amountBlock{
  display:flex;
  margin:auto;
  align-items: center;
  justify-content: center;
  height: 100%;

}



#buttonCount{
  margin:auto;
  background-color: black;
  border: black;
  color:white;
  font-size:60px;
  padding-left: 20px;
  padding-right:20px;
}


#buttonCountMinus{
  margin:auto;
  background-color: black;
  border: black;
  color:white;
  font-size:60px;
  padding-left: 20px;
  padding-right:20px;
  padding-bottom: 10px;
}

button:hover {
  opacity: 0.8;
}

#errorMSG{
  color:red;
  text-align:center;
  font-size: 12px;
  line-height: 1.5;
  margin-top:40px;
  margin-bottom:-60px;
  font-family: 'Arial', sans-serif;
}

#price{
  padding-top:20px;
}


#labelMint{
  color:white;
  margin:auto;
  font-size: 30px;
  color:red;
}



@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  33% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(3);
    opacity: 0;
  }
}

.button {
  align-items: center;
  background: #fc8823;
  box-shadow: 0 3px 2px 0 rgba(0,0,0,0.1);
  border-radius: 5px;
  height: 45px;
  width: 200px;
  padding: 0 30px;
  color: #fff;
  font-family: Lato, Arial, sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  transition: background .3s, transform .3s, box-shadow .3s;
   will-change: transform;
  
  &:hover {
    background: darken(#C06C84,10%);
    box-shadow: 0 4px 17px rgba(0,0,0,0.2);
    transform: translate3d(0, -2px, 0);
  }
  &:active {
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.1);
    transform: translate3d(0, 1px, 0);
  }
}

.block {
  pointer-events: none;
  background-color: rgb(164, 133, 133);
  margin: auto;
  margin-bottom: 0px;
}

.pulse {
 
  margin: auto;
  &:before, &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255,255,255,.4);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    opacity: 0;
    margin: auto;
  }
  &:before {
    animation: pulse 1.5s infinite linear;
  }
  &:after {
    animation: pulse 2s .4s infinite linear;
  }
  &:hover:before, &:hover:after {
    display: none;
  }
}

.wrapper{
  position:absolute;
  top:20%;
  left:50%;
  opacity: 0.5;
  transform:translate(-50%, -50%); 
}
.circle{
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: #fcdc29;
  border-radius: 50%;
  animation: loading 1.5s cubic-bezier(.8, .5, .2, 1.4) infinite;
  transform-origin: bottom center;
  position: relative;
}
@keyframes loading{
  0%{
    transform: translateY(0px);
    background-color: #fcdc29;
  }
  50%{
    transform: translateY(25px);
    background-color: #ef584a;
  }
  100%{
    transform: translateY(0px);
    background-color: #fcdc29;
  }
}
.circle-1{
  animation-delay: 0.1s;
}
.circle-2{
  animation-delay: 0.2s;
}
.circle-3{
  animation-delay: 0.3s;
}
.circle-4{
  animation-delay: 0.4s;
}
.circle-5{
  animation-delay: 0.5s;
}
.circle-6{
  animation-delay: 0.6s;
}



#loadingScreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadingAnimation {
  display: inline-block;
  width: 80px;
  height: 80px;
  border: 6px solid #ffffff;
  border-radius: 50%;
  border-top-color: #000000;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.loadingScreenLabel{
  display: flex;
text-align: center;
color:white;
}
